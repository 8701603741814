import axios, {AxiosResponse} from 'axios';
import {FiatPaymentInfoInterface, CardPaymentParamsInterface} from '@cere/services-types';
import {FIAT_SERVICE_API_URL} from '../../config/common';
import {getTenantId} from './tenant.service';

const api = axios.create({
  baseURL: FIAT_SERVICE_API_URL,
  headers: {
    'X-Tenant-Id': getTenantId(),
  },
});

export const getSessionInfo = (sessionId: string): Promise<FiatPaymentInfoInterface> => {
  return api
    .get(`card/session?sessionId=${sessionId}`)
    .then((data: AxiosResponse<FiatPaymentInfoInterface>) => data.data);
};

export const makePayment = (params: CardPaymentParamsInterface): Promise<boolean> => {
  return api.post(`card/payment`, params).then((data: AxiosResponse<boolean>) => data.data);
};
