import {useLazyQuery} from '@apollo/client';
import {GET_NFT} from '../queries/nfts';
import {useEffect, useState} from 'react';

export interface BaseNftInterface {
  nftId: string;
  cmsId: number;
  title: string;
  description: string;
  previewUrl: string;
}

export interface NftDataHookInterface {
  getNftDetails: (nftId: string, locale: string) => Promise<void>;
  loading: boolean;
  nftData?: BaseNftInterface;
  error?: any;
}

export const useNftData = (): NftDataHookInterface => {
  const [localGetNftDetails, {loading, error, data}] = useLazyQuery(GET_NFT);
  const [nft, setNft] = useState<BaseNftInterface | undefined>();
  const [nftId, setNftId] = useState<string | undefined>();

  useEffect(() => {
    if (!nftId) {
      return;
    }
    const cmsNft = data?.creatorNfts[0].cmsNfts[0];
    setNft({
      nftId,
      cmsId: cmsNft?.id,
      title: cmsNft?.title,
      description: cmsNft?.description,
      previewUrl: cmsNft?.cardImage?.url,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    getNftDetails: async (id: string, locale: string) => {
      setNftId(id);

      await localGetNftDetails({variables: {id, locale}});
    },
    loading,
    nftData: nft,
    error,
  };
};
