import React from 'react';
import './app.css';
import {PaymentCardDetailComponent} from './components/payment-card-detail/payment-card-detail.component';
import {theme} from './theme';
import {Slide, ThemeProvider} from '@mui/material';
import {BrowserRouter} from 'react-router-dom';
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import {SnackbarProvider} from 'notistack';
import {CMS_URL} from './config/common';

const apolloClient = new ApolloClient({
  uri: `${CMS_URL()}/graphql`,
  cache: new InMemoryCache(),
});

export function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Slide as any}
      >
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <PaymentCardDetailComponent />
          </ThemeProvider>
        </ApolloProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}
