import {gql} from '@apollo/client';

export const GET_NFT = gql`
  query CmsNft($id: ID!, $locale: String) {
    creatorNfts(where: {nft_id: $id, cmsNfts: {locale: $locale}}) {
      cmsNfts {
        id
        description
        title
        assets {
          content {
            url
          }
        }
        cardImage {
          url
        }
      }
    }
  }
`;
