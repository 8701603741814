import {createTheme} from '@mui/material';
import {blue} from '@mui/material/colors';

const values: {[key: string]: number} = {
  xs: 310,
  sm: 440,
  md: 700,
  lg: 920,
  xl: 1920,
};

export const theme = createTheme({
  palette: {
    background: {
      default: '#F5F5F7',
      paper: '#FFFFFF',
    },
    primary: {
      main: blue[300],
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    down: (key) => `@media (max-width:${values[key]}px)`,
    up: (key) => `@media (min-width:${values[key] + 1}px)`,
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: '800',
    },
    h2: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '700',
    },
    subtitle1: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#717178',
    },
  },

  components: {
    MuiOutlinedInput: {
      defaultProps: {},
      styleOverrides: {
        root: {
          fieldset: {
            borderRadius: '8px',
            border: '1px solid #E0E0E7;',
          },
        },
      },
    },
  },
});
