import {TEMP_SERVICE_TENANTS_PARAMS} from '../../config/common';

export type TenantParams = {
  cmsApiUrl: string;
};

let cachedCurrentTenant: string = '';

export const detectTenant = (): string => {
  const searchParams = new URLSearchParams(window.location.search);
  cachedCurrentTenant = searchParams.get('tenantId') || '';

  if (!cachedCurrentTenant) {
    throw new Error('tenantId parameter is required!');
  }

  return cachedCurrentTenant;
};

export const getTenantId = (): string => {
  if (!cachedCurrentTenant) {
    throw new Error('Tenant ID is empty!');
  }

  return cachedCurrentTenant;
};

let cachedTenantParams: TenantParams;

export const saveTenantParams = (params: TenantParams) => {
  cachedTenantParams = params;
};

export const getTenantParams = (): TenantParams => {
  if (!cachedTenantParams) {
    throw new Error('Tenant params are empty!');
  }

  return cachedTenantParams;
};

export const getTenantServicePropertyByKey = async <T>(tenantId: string, propertyName: string): Promise<T> => {
  // TODO: Implement fetch once config service ready instead of get from ENV params
  const temporaryParams = JSON.parse(TEMP_SERVICE_TENANTS_PARAMS!);
  const tenantConfig = temporaryParams[tenantId];

  if (!tenantConfig) {
    throw new Error(`No config for tenant: ${tenantId}`);
  }

  return tenantConfig[propertyName];
};

export const loadTenantConfig = async () => {
  try {
    const tenantId = detectTenant();
    const [cmsApiUrl] = await Promise.all(
      ['cmsApiUrl'].map((param) => getTenantServicePropertyByKey<string>(tenantId, param)),
    );
    saveTenantParams({
      cmsApiUrl,
    });
  } catch (e) {
    console.error(e);
  }
};
