import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {ReactComponent as PaymentCards} from '../../assets/payment-cards.svg';
import {ReactComponent as Cvv} from '../../assets/cvv.svg';
import {ReactComponent as Arrow} from '../../assets/arrow.svg';
import {KeyboardEvent, useEffect, useState} from 'react';
import {getSessionInfo, makePayment} from '../../shared/services/payment-card-detail.service.tx';
import {useSearchParams} from 'react-router-dom';
import {FiatPaymentInfoInterface} from '@cere/services-types';
import {useNftData} from '../../shared/hooks/use-nft-data';
import {theme} from '../../theme';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {getAllIsoCities, getAllIsoCountries, getAllIsoStates} from '../../shared/services/address.service.tx';
import {
  BackArrow,
  CenterBox,
  GridBox,
  GroupField,
  Header,
  LeftBox,
  NftImage,
  OneLineBox,
  Price,
  RightBox,
  Submit,
  Truncate,
  WhiteBox,
} from './payment-card-detail.style';
import {useSnackbar} from 'notistack';
import {CircleErrorEnum} from '@cere/services-types';
import {AddParamsToUrlHelper} from '../../shared/helpers/add-params-to-url.helper';

const isRequiredState = (country: string): boolean => ['US', 'CA'].includes(country);

const validationSchema = yup
  .object({
    name: yup.string().required('Name is required field').matches(/\w \w/g, 'Last name cannot be empty'),
    country: yup.string().required('Country is required field'),
    city: yup.string().required('City is required field'),
    state: yup.string().when('country', {
      is: isRequiredState,
      then: yup.string().required('State is required field'),
      otherwise: yup.string(),
    }),
    line1: yup.string().required('Address is required field'),
    line2: yup.string().optional(),
    postalCode: yup.string().required('Postal code is required field').max(16),

    cardNumber: yup
      .string()
      .required('Card number is required field')
      .matches(/^[\d ]{15,23}$/, 'Invalid card number'),
    expire: yup
      .string()
      .required('Expire date is required field')
      .matches(/^(0?[1-9]|1[012])\/\d{2}$/, 'Invalid expired date, it should be in format mm/yy'),
    cvv: yup
      .string()
      .required('CVV/CVC is required field')
      .min(3)
      .matches(/^\d{3,4}$/, 'Invalid number'),
  })
  .required();

export const PaymentCardDetailComponent = () => {
  let [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const locale = searchParams.get('locale') || 'en';

  const [sessionData, setSessionData] = useState<FiatPaymentInfoInterface | undefined>();
  const [countryList] = useState<Record<string, string>>(getAllIsoCountries());
  const [stateList, setStateList] = useState<Record<string, string>>({});
  const [cityList, setCityList] = useState<string[]>([]);
  const [requiredState, setRequiredState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const {getNftDetails, nftData} = useNftData();
  const {enqueueSnackbar} = useSnackbar();

  const goBack = () => {
    const cancelUrl = sessionData?.cancelUrl;
    if (cancelUrl) {
      window.location.href = cancelUrl;
    }
  };

  const {
    register,
    handleSubmit,
    setError,
    getValues: getFormValues,
    formState: {errors},
    setValue: setFormValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      name: '',
      country: '',
      city: '',
      state: '',
      line1: '',
      line2: '',
      postalCode: '',
      cardNumber: '',
      expire: '',
      cvv: '',
    },
  });

  const cvvAutoField = ({key}: KeyboardEvent<HTMLDivElement>) => {
    const value: string = getFormValues('expire');
    if (value.match(/^\d{2,2}$/) && key.match(/^\d{1,1}$/)) {
      setFormValue('expire', `${value}/`);
    }
  };

  const cardAutoField = ({key}: KeyboardEvent<HTMLDivElement>) => {
    const value: string = getFormValues('cardNumber');
    if (
      value.match(/(^\d{4,4}$|^\d{4,4} \d{4,4}$|^\d{4,4} \d{4,4} \d{4,4}$|^\d{4,4} \d{4,4} \d{4,4} \d{4,4}$)/) &&
      key.match(/^\d{1,1}$/)
    ) {
      setFormValue('cardNumber', `${value} `);
    }
  };

  const cardBlurField = () => {
    const value: string = getFormValues('cardNumber');
    const chunks = value.match(/.{4}/g) || [];
    if (value.match(/^[\d]{16}$/) && chunks.length === 4) {
      setFormValue('cardNumber', chunks.join(' '));
    }
  };

  const onSubmit: SubmitHandler<any> = async ({
    cardNumber,
    expire,
    cvv,
    name,
    city,
    country,
    line1,
    line2,
    postalCode,
    state,
  }) => {
    if (!sessionId) {
      return;
    }

    const [expMonth, expYear] = expire.split('/');

    try {
      setSubmitLoading(true);
      setSubmitDisabled(true);
      await makePayment({
        paymentSessionId: sessionId,
        cardNumber: cardNumber.replaceAll(' ', ''),
        expMonth: +expMonth,
        expYear: 2000 + +expYear,
        cvv: cvv,
        billingDetails: {
          name,
          country,
          city,
          district: state,
          line1,
          line2,
          postalCode,
        },
      });

      enqueueSnackbar('Payment success', {variant: 'success'});
      setTimeout(() => {
        const successUrl = sessionData?.successUrl;
        if (successUrl) {
          window.location.href = AddParamsToUrlHelper(successUrl, {session_id: sessionId});
        }
      }, 3000);
    } catch (err: any) {
      const errorCode = err?.response?.data?.code;
      const errorMessage = err?.response?.data?.message;

      if (
        [
          CircleErrorEnum.ACCOUNT_NUMBER_IS_INVALID_OR_MISSING,
          CircleErrorEnum.INVALID_CARD_NUMBER,
          CircleErrorEnum.CARD_FAILED,
          CircleErrorEnum.CARD_NOT_HONORED,
          CircleErrorEnum.CARD_ACCOUNT_INELIGIBLE,
        ].includes(errorCode)
      ) {
        setError('cardNumber', {message: errorMessage}, {shouldFocus: true});
      } else if ([CircleErrorEnum.THE_BILLING_LAST_NAME_CANNOT_BE_EMPTY].includes(errorCode)) {
        setError('name', {message: errorMessage}, {shouldFocus: true});
      } else if (
        [
          CircleErrorEnum.INVALID_COUNTRY_FORMAT,
          CircleErrorEnum.IBAN_COUNTRY_MISMATCH,
          CircleErrorEnum.UNSUPPORTED_COUNTRY,
        ].includes(errorCode)
      ) {
        setError('country', {message: errorMessage}, {shouldFocus: true});
      } else if ([CircleErrorEnum.INVALID_DISTRICT_FORMAT].includes(errorCode)) {
        setError('state', {message: errorMessage}, {shouldFocus: true});
      } else if (errorMessage) {
        enqueueSnackbar(errorMessage, {variant: 'default'});
      } else {
        console.error(err);
        enqueueSnackbar('Unknown Error', {variant: 'error'});
      }
      setSubmitDisabled(false);
    } finally {
      setSubmitLoading(false);
    }
  };

  const getPaymentInfo = async () => {
    if (!sessionId) {
      return;
    }
    const data: FiatPaymentInfoInterface = await getSessionInfo(sessionId);
    const nftId: string | undefined = data?.productInfo?.nftId;
    if (nftId) {
      await getNftDetails(nftId, locale);
    }
    setSessionData({...data, amount: data?.amount * 0.01});
  };

  useEffect(() => {
    getPaymentInfo().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress sx={{margin: 'auto'}} />
      </Box>
    );
  }

  if (!sessionData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <b>Session {sessionId} is not found!</b>
      </Box>
    );
  }

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <CenterBox>
        <GridBox>
          <BackArrow>
            <Arrow onClick={goBack} />
          </BackArrow>
          <Header>
            <Typography variant="h1">Pay by card</Typography>
          </Header>
          <LeftBox>
            <WhiteBox>
              <Typography variant="h2">Billing details</Typography>

              <FormControl fullWidth>
                <TextField
                  {...register('name')}
                  error={!!errors?.name?.message}
                  helperText={errors.name?.message}
                  required
                  label="Name"
                  name="name"
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="country-field">Country *</InputLabel>
                <Select
                  label="Country"
                  id="country-field"
                  {...register('country')}
                  onChange={(event: {target: {value: string; name: string}}) => {
                    setStateList(getAllIsoStates(event.target.value));
                    setCityList(getAllIsoCities(event.target.value));
                    setRequiredState(isRequiredState(event.target.value));
                  }}
                >
                  {Object.keys(countryList).map((countyCode: string) => (
                    <MenuItem value={countyCode}>{countryList[countyCode]}</MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors?.country?.message}>{errors.country?.message}</FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="state-field">State {requiredState ? '*' : ''}</InputLabel>
                <Select
                  label="State"
                  id="state-field"
                  {...register('state')}
                  onChange={(event: {target: {value: string; name: string}}) => {
                    const cities = getAllIsoCities(getFormValues().country, event.target.value);
                    setCityList(cities);
                  }}
                >
                  {Object.keys(stateList).map((stateCode: string) => (
                    <MenuItem value={stateCode}>{stateList[stateCode]}</MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors?.state?.message}>{errors.state?.message}</FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="city-field">City *</InputLabel>
                <Select label="City" id="city-field" {...register('city')}>
                  {cityList.map((val: string) => (
                    <MenuItem value={val}>{val}</MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors?.city?.message}>{errors.city?.message}</FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  {...register('line1')}
                  error={!!errors?.line1?.message}
                  helperText={errors.line1?.message}
                  required
                  label="Address line 1"
                  name="line1"
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  {...register('line2')}
                  error={!!errors?.line2?.message}
                  helperText={errors.line2?.message}
                  label="Address line 2"
                  name="line2"
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  {...register('postalCode')}
                  error={!!errors?.postalCode?.message}
                  helperText={errors.postalCode?.message}
                  required
                  label="Postal code"
                  name="postalCode"
                />
              </FormControl>
            </WhiteBox>
            <br />
            <WhiteBox>
              <Typography variant="h2">Card details</Typography>
              <FormControl fullWidth>
                <TextField
                  {...register('cardNumber')}
                  error={!!errors?.cardNumber?.message}
                  helperText={errors.cardNumber?.message}
                  required
                  label="Number"
                  name="cardNumber"
                  onBlur={cardBlurField}
                  onKeyUp={cardAutoField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <PaymentCards />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <OneLineBox>
                <FormControl fullWidth>
                  <TextField
                    {...register('expire')}
                    error={!!errors?.expire?.message}
                    helperText={errors.expire?.message}
                    required
                    onKeyUp={cvvAutoField}
                    label="MM / YY"
                    name="expire"
                  />
                </FormControl>
                <Box sx={{width: '16px'}}>&nbsp;</Box>
                <FormControl fullWidth>
                  <TextField
                    {...register('cvv')}
                    error={!!errors?.cvv?.message}
                    helperText={errors.cvv?.message}
                    required
                    label="CVV/CVC"
                    name="cvv"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Cvv />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </OneLineBox>
            </WhiteBox>
          </LeftBox>
          <RightBox>
            {nftData?.cmsId && (
              <WhiteBox>
                <GroupField>
                  <NftImage background={nftData.previewUrl} theme={theme} />
                  <Typography variant="subtitle1">NFT name</Typography>
                  <Typography>{nftData.title}</Typography>
                  <Divider />
                </GroupField>
                <GroupField>
                  <Typography variant="subtitle1">NFT ID</Typography>
                  <Truncate>{nftData?.nftId}</Truncate>
                  <Divider />
                </GroupField>
                <OneLineBox>
                  <Typography variant="subtitle1" sx={{margin: '0 auto 0 0'}}>
                    Price
                  </Typography>
                  <Typography
                    sx={{
                      margin: '0 10px 0 auto',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    $ {sessionData?.amount}
                  </Typography>
                </OneLineBox>
                <Divider />
                <OneLineBox>
                  <Typography variant="subtitle1" sx={{margin: '0 auto 0 0'}}>
                    Quantity
                  </Typography>
                  <Typography
                    sx={{
                      margin: '0 10px 0 auto',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    {sessionData?.quantity}
                  </Typography>
                </OneLineBox>
                <Divider />

                <Price>
                  ${' '}
                  {((sessionData?.amount || 0) * (sessionData?.quantity || 1)).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Price>
                <Submit>
                  <Button
                    sx={{width: '100%'}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitDisabled}
                  >
                    {submitLoading ? <CircularProgress size="30px" /> : 'Pay'}
                  </Button>
                </Submit>
              </WhiteBox>
            )}
          </RightBox>
        </GridBox>
      </CenterBox>
    </Box>
  );
};
