import {Country, State, City} from 'country-state-city';
import {ICity} from 'country-state-city/dist/lib/interface';

const circleCountryList = [
  'AX',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BH',
  'BD',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'CM',
  'CA',
  'KY',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CK',
  'CR',
  'CI',
  'HR',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IE',
  'IM',
  'IL',
  'IT',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'MT',
  'MH',
  'MQ',
  'MR',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'ME',
  'MS',
  'MA',
  'MZ',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PW',
  'PS',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'MK',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'ZA',
  'GS',
  'ES',
  'LK',
  'SR',
  'SJ',
  'SE',
  'CH',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'AE',
  'GB',
  'US',
  'UY',
  'UZ',
  'VN',
  'VG',
  'WF',
  'EH',
  'ZM',
];

export const getAllIsoCountries = () => {
  return Country.getAllCountries().reduce((a, item) => {
    return circleCountryList.includes(item.isoCode) ? {...a, [item.isoCode]: item.name} : a;
  }, {});
};

export const getAllIsoStates = (countryIsoCode: string) => {
  return State.getStatesOfCountry(countryIsoCode).reduce((a, item) => ({...a, [item.isoCode]: item.name}), {});
};

export const getAllIsoCities = (countryIsoCode: string, stateIsoCode?: string): string[] => {
  const cities: ICity[] = stateIsoCode
    ? City.getCitiesOfState(countryIsoCode, stateIsoCode)
    : City.getCitiesOfCountry(countryIsoCode) || [];

  return cities.map((item: ICity) => item.name);
};
