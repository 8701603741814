import {styled, Theme} from '@mui/material';

export const CenterBox = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 16px 0 16px',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('sm')]: {
    padding: '0 40px 0 40px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0',
  },
  minHeight: '100vh',
}));

export const GridBox = styled('div')(({theme}) => {
  return {
    margin: 'auto',
    flex: '1',
    display: 'grid',

    gridTemplateColumns: '1fr 1fr',

    [theme.breakpoints.up('lg')]: {
      width: '905px',
      gridGap: '20px',
      gridTemplateRows: '50px 1fr',
    },
  };
});

export const BackArrow = styled('div')(() => {
  return {
    gridColumn: '1/3',
    padding: '17px 0 17px 0',
    '> svg': {
      cursor: 'pointer',
    },
  };
});

export const Header = styled('div')(({theme}) => ({
  gridColumn: '1/3',
  padding: '12px 0 19px 0',
  [theme.breakpoints.up('lg')]: {
    padding: '0 0 0 32px',
  },
}));

export const LeftBox = styled('div')(({theme}) => ({
  gridColumn: '1/3',
  [theme.breakpoints.up('lg')]: {
    gridColumn: '1/2',
    justifySelf: 'end',
    width: '464px',
  },
}));

export const RightBox = styled('div')(({theme}) => ({
  gridColumn: '1/3',
  paddingTop: '16px',
  [theme.breakpoints.up('lg')]: {
    gridColumn: '2/3',
    width: '405px',
    justifySelf: 'start',
    paddingTop: '0',
    paddingLeft: '16px',
  },
}));

export const WhiteBox = styled('div')(({theme}) => ({
  borderRadius: '12px',
  padding: '16px',
  backgroundColor: theme.palette.background.paper,
  label: {
    background: 'white',
  },
  '> div:not(:first-child)': {
    margin: '10px 0 10px 0',
  },
  [theme.breakpoints.up('sm')]: {
    padding: '24px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '32px',
  },
}));

export const OneLineBox = styled('div')(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.up('sm')]: {
    flexWrap: 'nowrap',
  },
}));

export const NftImage = styled('div')(({background, theme}: {background: string; theme: Theme}) => ({
  backgroundImage: `url("${background}")`,
  width: '48px',
  height: '48px',
  float: 'right',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderRadius: '6px',
  marginBottom: '32px',
  [theme.breakpoints.up('lg')]: {
    height: '341px',
    width: '341px',
    borderRadius: '12px',
  },
}));

export const GroupField = styled('div')(() => ({
  p: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#161616',
    padding: '4px 0 4px 0',
  },
}));

export const Price = styled('div')(() => ({
  fontSize: '33px',
  fontWeight: 600,
  padding: '25px 0 25px 0',
  textAlign: 'right',
}));

export const Truncate = styled('div')(() => ({
  fontWeight: '600',
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const Submit = styled('div')(() => ({
  button: {
    width: '100%',
    color: '#F8F8FA',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    background: 'linear-gradient(277.13deg, #B01C63 11.98%, #FA0C58 83.77%)',
    borderRadius: '40px',
    padding: '9px',
  },
}));
