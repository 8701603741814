import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './report-web-vitals';
import {loadTenantConfig} from './shared/services/tenant.service';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  await loadTenantConfig();
  const {App} = require('./app');

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
})();

reportWebVitals();
